import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ maxWidth: `400px`, margin: `30px auto` }}>
      <Image />
    </div>
    <p style={{ textAlign: `center`, fontSize: '20px', fontFamily: 'monospace', letterSpacing: '7px' }}>Comming soon ...</p>
  </Layout>
)

export default IndexPage
